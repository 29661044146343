import React from 'react';
import { withRouter, Link, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Alert from '../../Services/Alert';
import Api from '../../Services/Api';

export const Register = (props) => {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const onSubmit = async (data) => {
    Api.post('admin/signup', data).then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/login');
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }

  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Create your account</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <a href="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="assets/images/logo-light.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a>

                    <a href="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="./assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label htmlFor="firstname" className="form-label">First Name</label>
                        <input type="text" className="form-control" id="firstname" placeholder="Enter first name" {...register("firstname", { required: true, maxLength: 80 })} />
                        <div className="error">
                            {errors.firstname?.type === 'required' && "First Name is required"}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lastname" className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="lastname" placeholder="Enter last name" {...register("lastname", { required: true, maxLength: 80})} />
                        <div className="error">
                            {errors.lastname?.type === 'required' && "Last Name is required"}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Enter email" {...register("email", { required: true, pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address"
                          } })} />
                        <div className="error">
                            {errors.email?.type === 'required' && "Email is required"}
                            {errors.email?.type === 'pattern' && errors.email.message}
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" {...register("password", { required: true, maxLength: 80 })}/>
                        <div className="error">
                            {errors.password?.type === 'required' && "Password is required"}
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary waves-effect waves-light" type="submit">Sign Up</button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
              <div className="mt-5 text-center">

                <div>
                  <p>Already have an account ? <Link to="/login" className="fw-medium text-primary"> Log In </Link> </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Register);