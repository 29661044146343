import Api from './Api';

const prefix = 'admin/product/';
class Product {
    constructor(props){}

    save(obj) {
        return Api.post(prefix+'create', obj).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    list(obj) {
        return Api.post(prefix+'list', obj).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    get(id) {
        return Api.get(prefix+'detail/'+id).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    update(obj) {
        return Api.post(prefix+'update', obj).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
    
    delete(id) {
        return Api.post(prefix+'delete/'+id).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
    update_status(obj) {
        return Api.post(prefix+'status_change', obj).then((resp)=>{
            if(resp.data.status === 0 && resp.data.message === 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
    
}
export default new Product();