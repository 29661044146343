import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

export const Home = () => {
  const containerRef = useRef();
  return (
    <React.Fragment>
      <section
        className="content-section"
      >
        <div className="container mr-auto">
          <div
            className="multiple-box-section"
            ref={containerRef} /* style={{background: "#f00"}} */
          >
            Welcome to HomePage

          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default withRouter(Home);
