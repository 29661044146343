import React, { useEffect, useState } from 'react';
import { withRouter, Link, useHistory } from "react-router-dom";
import Api from '../../Services/Api';
import { useForm } from 'react-hook-form';
import { useLoading } from "../../Services/LoadingContext";

export const Login = (props) => {
  let [success, setSuccess] = useState('');
  let [fail, setFail] = useState('');
  let history = useHistory();
  const { setLoading } = useLoading();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  useEffect(() => {
    if (localStorage.getItem('admin')) {
      history.push("/dashboard");
    }
  }, []);
  const doLogin = async (data) => {
    //var data = { email: email, password: password };
    setLoading(true);
    Api.post('admin/login', data).then(resp => {
      setLoading(false);
      var respData = resp.data;
      if (respData.status === 1) {
        localStorage.setItem("user_email", respData.data.email);
        // history.push("/otp");
        localStorage.setItem('authToken', respData.data.auth_token);
        localStorage.setItem("admin", JSON.stringify(respData.data));
        history.push("/dashboard");
      } else {
        setFail(respData.message);
      }
    }).catch((error) => {
      setLoading(false);
      setFail(error.message);
    });
  }
  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img src="./assets/images/profile-img.png" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <a href="#;" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="./assets/images/logo-light.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a>

                    <a href="#;" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="./assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="p-2">
                    {fail ? <div className='alert alert-danger'>{fail}</div> : ''}
                    {success ? <div className='alert alert-success'>{success}</div> : ''}
                    <form className="form-horizontal" onSubmit={handleSubmit(doLogin)}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="text" className="form-control" id="email" placeholder="Enter Email" {...register("email", { required: true, pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address"
                          } })} />
                          <div className="error">
                            {errors.email?.type === 'required' && "Email is required"}
                            {errors.email?.type === 'pattern' && errors.email.message}
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <div className="input-group auth-pass-inputgroup">
                          <input type="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" {...register("password", { required: true, maxLength: 80 })} />
                          <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                        </div>
                        <div className="error">
                            {errors.password?.type === 'required' && "Password is required"}
                        </div>
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="remember-check" />
                        <label className="form-check-label" htmlFor="remember-check">
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                      </div>
                      <div className="mt-4 text-center">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5 text-center">
                <div>
                    <p>Don't have an account ? 
                    <Link to="/register" className="fw-medium text-primary">Signup now</Link></p>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Login);