import React, { useEffect, useState } from "react";
import { withRouter, useLocation, useHistory, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Api from "../Services/Api";
import Alert from "../Services/Alert";
import { useLoading } from "../Services/LoadingContext";

export const Details = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [modalShowThankYou, setModalShowThankYou] = useState(false);
  const pricePerBox = 100;
  const formValues = getValues();
  const location = useLocation();
  const selectedBoxData = location?.state?.selectedData || [];
  const [selectedLocalBox, setSelectedLocalBox] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [stringSelectedBoxData, setStringSelectedBoxData] = useState(
    selectedBoxData.toString().replace(/,[s]*/g, ", ")
  );
  const [finalPrice, setFinalPrice] = useState(
    selectedBoxData.length > 0
      ? parseFloat(selectedBoxData.length * pricePerBox, 10)
      : 0
  );
  const [sessionId, setSessionId] = useState(null);
  const history = useHistory();
  const { setLoading } = useLoading();

  const deleteItem = async (box) => {
    const sData = { session_id: sessionId, slot: box.boxCode};
    setLoading(true);
    Api.post("user/delete-single-temp-slot", sData)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        const removePaymentType = selectedLocalBox.filter( (item) => item.boxCode !== box.boxCode );
        const removeStrings = stringSelectedBoxData.split(", ");
        const removeStringsArr = removeStrings.filter(
          (itm2) => itm2 !== box.boxCode
        );
        setStringSelectedBoxData(removeStringsArr.join(", "));
        setFinalPrice(
          removePaymentType.length > 0 ? parseFloat( removePaymentType.length * pricePerBox, 10 ) : 0
        );
        setSelectedLocalBox(removePaymentType);
        if(selectedLocalBox.length <= 1){
          history.push("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message);
      });
    
  };
  const onSubmit = async (data) => {
    data.images = selectedLocalBox;
    data.gridids = stringSelectedBoxData;
    data.price = finalPrice;
    data.session_id = sessionId;
    data.name = data.Name;
    // console.log('submit',data);
    setLoading(true);
    Api.post("admin/request-details", data)
      .then((res) => res.data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          // Alert.success(res.message)
          // setModalShowThankYou(true)
          history.push("/");
        } else {
          Alert.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(error.message);
      });
  };

  const changeImage = (e, boxCode) => {
    if (e.target.files && e.target.files.length > 0) {
      let img = new Image();
      img.src = URL.createObjectURL(e.target.files[0]);
      let nameExtArr = e.target.files[0].name.split(".");
      let beforeExt = nameExtArr[nameExtArr.length - 1];
      if (["jpg", "jpeg", "png"].indexOf(beforeExt.toLowerCase()) > -1) {
        img.onload = function () {
          if (this.width == 96 && this.height) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(e.target.files[0]);

            fileReader.onloadend = function (e) {
              const imageExtension = fileReader.result.substring(
                "data:image/".length,
                fileReader.result.indexOf(";base64")
              );
              const updatedColumn = selectedLocalBox.map((box, boxIndex) => {
                let updatedData = box;
                if (updatedData.boxCode === boxCode) {
                  const newState = selectedLocalBox;
                  updatedData = {
                    ...newState[boxIndex],
                    base64: fileReader.result.replace(
                      "data:image/" + imageExtension + ";base64,",
                      ""
                    ),
                    extension: imageExtension,
                    result: fileReader.result,
                  };
                }
                return updatedData;
              });
              setSelectedLocalBox(updatedColumn);
            }.bind(this);
          } else {
            Alert.errorToast("Image dimensions must be 96 X 96");
          }
        };
      } else {
        Alert.errorToast("Only jpg, jpeg & png file allowed");
      }
    }
  };

  useEffect(() => {
    if (
      location?.state?.selectedData &&
      location?.state?.selectedData.length > 0
    ) {
      const tmpData = [];
      const stateSelectedBox = location?.state?.selectedData;
      for (let index = 0; index < stateSelectedBox.length; index++) {
        tmpData.push({
          boxCode: stateSelectedBox[index],
          base64: "",
          extension: "",
          result: "",
        });
      }
      setSelectedLocalBox(tmpData);
    }
  }, [location?.state?.selectedData]);
  useEffect(() => {
    setSessionId(localStorage.getItem('lcSessionId'));
  }, []);

  return (
    <>
      <Header />
      {/* <div className="content-section">
      </div> */}
      <div className="account-pages my-5 custom-padding div_overflow">
        <div className="container">
          <div className="overflow-hidden">
            {/* old-form */}
            {/* <div className="p-2">
                <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                  <div className="modal-parts">
                    <div className="row pt-4">
                      <div className="col-lg-6">
                        <h4>Your Details</h4>
                        <div className="mb-4 set-relative">
                          <input type="text" className={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? 'form-control error-design' : 'form-control'} id="email" placeholder={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? '' : 'Enter Email'} {...register("email", {
                            required: true, pattern: {
                              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email address"
                            }
                          })} />
                          <div className="error error-position">
                            {errors.email?.type === 'required' && "Email is required"}
                            {errors.email?.type === 'pattern' && errors.email.message}
                          </div>
                          <p className="your_details_texts_p">
                            Not a correct Email format.
                          </p>
                        </div>
                        <div className="mb-4 set-relative">
                          <input type="text" className={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? 'form-control error-design' : 'form-control'} id="message" placeholder={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? '' : 'Enter Message'} {...register("message", { required: true, maxLength: 80 })} />
                          <div className="error error-position">
                            {errors.message?.type === 'required' && "Message is required"}
                          </div>
                          <p className="your_details_texts_p">
                            This message will appear on Desktop when a visitor hovers over your image. On mobile when a visitor taps your image.
                          </p>
                        </div>
                        <div className="mb-5 set-relative">
                          <input type="text" className={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? 'form-control error-design' : 'form-control'} id="link" placeholder={typeof errors.email !== 'undefined' && Object.keys(errors.email).length > 0 ? '' : 'Enter Link'} {...register("link", {
                            required: true, pattern: {
                              value: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                              message: "Invalid email address"
                            }
                          })} />
                          <div className="error error-position">
                            {errors.link?.type === 'required' && "Link is required"}
                            {errors.link?.type === 'pattern' && "Invalid Link"}
                          </div>
                          <p className="your_details_texts_p">
                            The link is where you want visitor to go when they click your image.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h4>Your Image(s)</h4>
                        <div className="images">
                          <div className="row pt-4">
                            {selectedLocalBox.length > 0 && selectedLocalBox.map((box, index) => (
                              <div className="col-lg-4" key={`selected-box-images-${box.boxCode}-${index}`}>
                                <label className="lbl_image_1" style={box.base64 === '' ? { textAlign: 'center', fontSize: '30px' } : { background: "none" }}>
                                  {box.base64 !== '' &&
                                    <img src={box.result} className={`detail_img detail_img_${index}`} />
                                  }
                                  {box.base64 === '' &&
                                    '96X96'
                                  }
                                  <input type="file" name={`image${index}`} onInput={(e) => changeImage(e, box.boxCode)}
                                    {...register(`image${index}`, { required: true })}
                                  />
                                  <div className="error" style={ { fontSize: '12px' } }>
                                    {errors[`image${index}`]?.type === 'required' && `Image of ${box.boxCode} is required`}
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="total-price">
                          <h3>Total</h3>
                          <span>{finalPrice} $</span>
                        </div>
                      </div>
                    </div>
                    <div className="you-will-text">
                      <p>You will send you an email once your request has been approved with a payment link. <br />
                        *30% of all proceeds go to Stray Dog Shelter Dubai.</p>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I agree to the Terms & Conditions and Privacy Policy."
                          {...register("agree", { required: true })}
                        />
                        <div className="error">
                          {errors.agree?.type === 'required' && "Please agree to the Terms & Conditions and Privacy Policy first."}
                        </div>
                      </Form.Group>
                    </div>
                    <Button className="submit" type="submit" style={{ "width": "100%" }}>Submit</Button>
                  </div>
                </form>
              </div> */}
            {/* end-old-form */}
            {/* new-design */}
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
              <div className="row your-details-bg-sec-width">
                <div className="col-lg-6">
                  <div className="your-details-sec">
                    <div className="form-details">
                      <h2 className="yr-title">Your Details </h2>
                      <Form.Group
                        className="mb-3 set-relative"
                        controlId="formBasicName"
                      >
                        <Form.Label>First & Last Name</Form.Label>
                        <input
                          type="text"
                          className={
                            typeof errors.Name !== "undefined" &&
                            Object.keys(errors.Name).length > 0
                              ? "form-control error-design"
                              : "form-control"
                          }
                          id="Name"
                          {...register("Name", {
                            required: true,
                          })}
                        />
                        <div
                          className={
                            formValues.Name !== ""
                              ? "error"
                              : "error error-position"
                          }
                        >
                          {errors.Name?.type === "required" &&
                            "Name is required"}
                          {errors.Name?.type === "pattern" &&
                            errors.Name.message}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3 set-relative"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <input
                          type="text"
                          className={
                            typeof errors.email !== "undefined" &&
                            Object.keys(errors.email).length > 0
                              ? "form-control error-design"
                              : "form-control"
                          }
                          id="email"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <div
                          className={
                            formValues.email !== ""
                              ? "error"
                              : "error error-position"
                          }
                        >
                          {errors.email?.type === "required" &&
                            "Email is required"}
                          {errors.email?.type === "pattern" &&
                            errors.email.message}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-details block-details-padding">
                      <h2 className="yr-title">Block Details</h2>
                      <Form.Group
                        className="mb-3 set-relative"
                        controlId="formBasicMessage"
                      >
                        <Form.Label>Block Message</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          id="message"
                          placeholder={
                            typeof errors.email !== "undefined" &&
                            Object.keys(errors.email).length > 0
                              ? ""
                              : "Enter Message"
                          }
                          {...register("message")}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 set-relative"
                        controlId="formBasicLink"
                      >
                        <Form.Label>Block Link</Form.Label>
                        <input
                          type="text"
                          className={
                            typeof errors.link !== "undefined" &&
                            Object.keys(errors.link).length > 0
                              ? "form-control error-design"
                              : "form-control"
                          }
                          id="link"
                          placeholder={
                            typeof errors.link !== "undefined" &&
                            Object.keys(errors.link).length > 0
                              ? ""
                              : "Enter Link"
                          }
                          {...register("link", {
                            required: true,
                            pattern: {
                              value:
                                /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                              message: "Please enter valid website address",
                            },
                          })}
                        />
                        <div
                          className={
                            formValues.link !== ""
                              ? "error"
                              : "error error-position"
                          }
                        >
                          {errors.link?.type === "required" &&
                            "Website address is required"}
                          {errors.link?.type === "pattern" &&
                            "Please enter valid website address"}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="desktop-btn">
                      <Button
                        className="submit"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        Submit Request
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 offset-lg-1">
                  <div className="cart-Summary">
                    <div className="summry-details">
                      <h3 className="yr-title">
                        Cart Summary
                        <span>
                          <Link
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditMode(!editMode);
                            }}
                          >
                            {editMode ? "Done" : "Edit"}
                          </Link>
                        </span>
                      </h3>
                      {selectedLocalBox.length > 0 &&
                        selectedLocalBox.map((box, index) => (
                          <div
                            className="img-summary"
                            key={`selected-box-images-${box.boxCode}-${index}`}
                          >
                            <div className="images">
                              {editMode === true && (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => {
                                    deleteItem(box);
                                  }}
                                >
                                  <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                              )}
                              <div>
                                <label
                                  className="lbl_image_1"
                                  style={
                                    box.base64 === ""
                                      ? {
                                          textAlign: "center",
                                          fontSize: "20px",
                                        }
                                      : { background: "none" }
                                  }
                                >
                                  {box.base64 !== "" && (
                                    <img
                                      src={box.result}
                                      className={`detail_img detail_img_${index}`}
                                      alt=""
                                    />
                                  )}
                                  {box.base64 === "" && "96X96"}
                                  <input
                                    type="file"
                                    name={`image${index}`}
                                    onInput={(e) => changeImage(e, box.boxCode)}
                                    {...register(`image${index}`, {
                                      required: true,
                                    })}
                                  />
                                  <div
                                    className="error"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {errors[`image${index}`]?.type ===
                                      "required" &&
                                      `Image of ${box.boxCode} is required`}
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="summary-content">
                              <h3>1 x {box.boxCode} $100.00</h3>
                              <p>Upload your image by clicking on the block</p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="summry-price">
                      <h2>Total ${finalPrice}</h2>
                    </div>
                  </div>

                  <div className="all-proceed">
                    <p>
                      33% of all proceeds will be donated to{" "}
                      <Link href=""> @straydogscenter </Link> to assist in
                      housing and feeding stray dogs in the United Arab
                      Emirates.
                    </p>
                  </div>

                  <div className="mobile-btn">
                    <Button
                      className="submit"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      Submit Request
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            {/* new-design-end */}
          </div>
        </div>
      </div>
      <Modal
        size="small"
        className="custom-modal-class"
        show={modalShowThankYou}
        onHide={() => setModalShowThankYou(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-parts">
            <div className="thankyou-text">
              <h4 className="text-center">Thank You!</h4>
              <p>
                Your request is being processed and you will receive a
                confirmation email shortly with a payment link.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default withRouter(Details);
