import React, { useEffect, useState } from 'react';
import {  withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import Product from '../../Services/Product';
import Alert from '../../Services/Alert';
import { convertToBase64 } from './Custom';

export const ProductAdd = (props) => {
	const history = useHistory();
  const { id } = useParams();
  const [proImage, setProImage] = useState();
  const [proClubImage, setProClubImage] = useState();
  const { register, handleSubmit, setValue, getValues, trigger, reset, formState: { errors }, watch } = useForm();
  useEffect(() => {
    if(id){
      Product.get(id).then((res) => res.data?.data).then((res) => {
        console.log('res', res);
        const userData = {
          name: res.data.name,
          description: res.data.description,
          no_of_block: res.data.no_of_block,
          no_of_rows: res.data.no_of_rows,
          no_of_cols: res.data.no_of_cols,
          price_each_block: res.data.price_each_block,
          club_name: res.data.club_name,
          raised_per: res.data.raised_per,
          status: res.data.is_active.toString(),
        }
        setProImage(`${process.env.REACT_APP_API_URL}${res.imgPath}${res.data.image}`);
        setProClubImage(`${process.env.REACT_APP_API_URL}${res.imgClubPath}${res.data?.club_logo}`);
        reset(userData);
      });
    }
  }, [id]);
  useEffect(() => {
    const rows = getValues()?.no_of_rows;
    const cols = getValues()?.no_of_cols;
    if(rows && parseInt(rows) > 0 && cols && parseInt(cols) > 0){
      setValue('no_of_block', (parseInt(rows) * parseInt(cols)));
      trigger('no_of_block');
    }
  }, [watch('no_of_rows'), watch('no_of_cols')]);

const onImageChange = async (e) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(e.target.files[0]);
  setProImage(fileReader.result);
  console.log(e.target.files[0]);
};

const get64Ext = async (imageObj) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = URL.createObjectURL(imageObj[0]);
    let nameExtArr = imageObj[0].name.split(".");
    let beforeExt = nameExtArr[nameExtArr.length - 1];
    let base64 = undefined;
    let extension = undefined;
    if (["jpg", "jpeg", "png"].indexOf(beforeExt.toLowerCase()) > -1) {
      img.onload = function () {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imageObj[0]);

        fileReader.onloadend = function (e) {
          extension = fileReader.result.substring(
            "data:image/".length,
            fileReader.result.indexOf(";base64")
          );
          base64 =  fileReader.result.replace("data:image/" + extension + ";base64,", "");
          resolve({ extension, base64 });
        }.bind(this);
      };
    } else {
      Alert.errorToast("Only jpg, jpeg & png file allowed");
      reject(null)
    }
  });
  
};
const onSubmit = async (data) => {
  console.log('data', data);
  if(data?.image && data?.image?.length > 0){
    /* const base64 = await convertToBase64(data.image[0]);
    const extension = base64.substring("data:image/".length, base64.indexOf(";base64"))
    const imagev = base64.replace("data:image/"+data.extension+";base64,", "");
    data.image = { extension : extension, base64: imagev }; */
    data.image = await get64Ext(data?.image);
  }
  if(data?.club_logo && data?.club_logo?.length > 0){
    data.club_logo = await get64Ext(data?.club_logo);
  }
  
	let respObj;
	if(id){
		data.id = id;
		respObj = Product.update(data);
	} else {
		respObj = Product.save(data);
	}
    respObj.then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/products');
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  } 
  return (
    <>
    {console.log('rendered')}
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Product Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      {/* {errors && JSON.stringify(errors)} */}
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" className="form-control" {...register("name", {required: true, maxLength: 80})} />
                            <div className="error">
                                {errors.name?.type === 'required' && "Name is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
							              <textarea placeholder="Enter Description" className="form-control" {...register("description", {required: true, maxLength: 1500})}></textarea>
                            <div className="error">
                                {errors.description?.type === 'required' && "Description is required"}
                                {errors.description?.type === 'maxLength' && "Description must be less then equal to 1500 character"}
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">
                          <Form.Group className="mb-3" controlId="no_of_rows">
                            <Form.Label>No. of rows</Form.Label>
                            <Form.Control type="text" placeholder="Enter No. of Rows" className="form-control" {...register("no_of_rows", {required: true, maxLength: 80})} disabled={id && getValues('raised_per') > 0} />
                                <div className="error">
                                    {errors.no_of_rows?.type === 'required' && "No. of rows is required"}
                                </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3" controlId="no_of_cols">
                            <Form.Label>No. of Columns</Form.Label>
                            <Form.Control type="text" placeholder="Enter No. of Columns" className="form-control" {...register("no_of_cols", {required: true, maxLength: 80})} disabled={id && getValues('raised_per') > 0} />
                                <div className="error">
                                    {errors.no_of_cols?.type === 'required' && "No. of Columns is required"}
                                </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3" controlId="no_of_block">
                            <Form.Label>No. of block</Form.Label>
                            <br />
                            <Form.Label>{getValues()?.no_of_block}</Form.Label>
                            <Form.Control type="hidden" placeholder="Enter No. of block" className="form-control" {...register("no_of_block", {required: true, maxLength: 80})} disabled={id && getValues('raised_per') > 0} />
                              <div className="error">
                                  {errors.no_of_block?.type === 'required' && "No. of block is required"}
                              </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-3">
                          <Form.Group className="mb-3" controlId="price_each_block">
                            <Form.Label>Price Each Block</Form.Label>
                            <Form.Control type="text" placeholder="Enter Price Each Block" className="form-control" {...register("price_each_block", {required: true, maxLength: 80})} disabled={id && getValues('raised_per') > 0} />
                                <div className="error">
                                    {errors.price_each_block?.type === 'required' && "Price Each Block is required"}
                                </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Choose Image (300x300 px)</Form.Label>
                                <Form.Control type="file" onChange={onImageChange} className="form-control" {...register("image", {required: id ? false: true, maxLength: 80})} />
                                <div className="error">
                                    {errors.image?.type === 'required' && "Image is required"}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-lg-6">
                              <img src={proImage} alt="product" style={{height: '200px', width:"100%"}}/>
                            </div>
                          </div>
                          
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Status</Form.Label>
                            <div className="col-lg-12">
                              <Form.Check inline label="Active" value="1" id="active-status" defaultChecked type="radio" {...register("is_active", {required: true})} />
                              <Form.Check inline label="Inactive" id="inactive-status" type="radio" value="0" {...register("is_active", {required: true})} />
                              <div className="error">
                                  {errors.status?.type === 'required' && "Active Status is required"}
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="club_name">
                            <Form.Label>Club Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Club name" className="form-control" {...register("club_name", { required: true, maxLength: 80})} />
                            <div className="error">
                                {errors.club_name?.type === 'required' && "Club name is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Club Image (300x300 px)</Form.Label>
                                <Form.Control type="file" onChange={onImageChange} className="form-control" {...register("club_logo", {required: id ? false: true, maxLength: 80})} />
                                <div className="error">
                                    {errors.club_logo?.type === 'required' && "Club Logo is required"}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col-lg-6">
                              <img src={proClubImage} alt="product" style={{height: '200px', width:"100%"}}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(ProductAdd);