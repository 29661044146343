import React, { Fragment, useEffect, useState } from "react";
import { withRouter, useLocation } from 'react-router-dom';
import Footer from "./Footer";
import Header from "./Header";

export const PreviewImage = () => {
  const location = useLocation();
  const [images, setImages] = useState([]);

  const download_img = (imageLink, name, extension) => {
    var element = document.createElement("a");
    element.href = imageLink;
    element.download = `${name}.${extension}`;
    element.click();
  };

  useEffect(() => {
    if (location?.state?.images && location?.state?.images.length > 0) {
      setImages(location?.state?.images);
    }
  }, [images]);

  return (
    <Fragment>
      <Header />
      <div className="preview-images my-5">
        <div className="container">
          <div className="card overflow-hidden">
            <h4 className="card-title mb-4">Images</h4>
            <div className="card-body pt-0">
              <div className="row">
                {images.map((img, index) => (
                  <div className="col-md-2 col-lg-2 col-xl-2" key={index}>
                    <div className="p-2 border">
                      <img src={img.imageUrl} />
                    </div>
                    <div className="details">
                      <h6>Block No:- {img.boxCode}</h6>
                      <h6>Image:- {img.boxCode}.{img.extension}</h6>
                      <button onClick={() => download_img(img.result, img.boxCode, img.extension)} className="btn btn-primary"><i className='mdi mdi-download'></i></button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default withRouter(PreviewImage);
