import Layout from './Components/Layout'
import { BrowserRouter as Router } from "react-router-dom";
import { LoadingProvider } from './Services/LoadingContext';

function App() {
  return (<>
    <Router>
      <LoadingProvider>
        <Layout/>
      </LoadingProvider>
    </Router>
  </>)
}

export default App;
