import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import Api from '../../Services/Api';
import { useForm } from 'react-hook-form';
import { useLoading } from "../../Services/LoadingContext";

export const Otp = (props) => {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [success, setSuccess] = useState('');
  let [fail, setFail] = useState('');
  let history = useHistory();
  const { setLoading } = useLoading();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  useEffect(() => {
    if (localStorage.getItem('admin')) {
      history.push("/dashboard");
    }
  }, []);
  const verifyOtp = async (data) => {
    var res = { otp: data.otp, email: localStorage.getItem('user_email') };
    setLoading(true);
    Api.post('admin/verify-otp', res).then(resp => {
      setLoading(false);
      var respData = resp.data;
      if (respData.status == 1) {
        setSuccess(respData.message);
        localStorage.setItem('authToken', respData.data.auth_token);
        localStorage.setItem("admin", JSON.stringify(respData.data));
        setTimeout(function () {
          history.push("/upload-desktop-image");
        }, 1500);

      } else {
        setFail(respData.message);
      }
    }).catch((error) => {
      setLoading(false);
      console.log(error);
      setFail(error.message);
    });
  }
  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">OTP</h5>
                        <p>Enter Correct OTP to Sign In</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img src="./assets/images/profile-img.png" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="auth-logo">
                    <a href="#;" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="./assets/images/logo-light.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a>

                    {/* <a href="#;" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src="./assets/images/logo.svg" alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </a> */}
                  </div>
                  <div className="p-2">
                    {fail ? <div className='alert alert-danger'>{fail}</div> : ''}
                    {success ? <div className='alert alert-success'>{success}</div> : ''}
                    <form className="form-horizontal" onSubmit={handleSubmit(verifyOtp)}>
                      
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">OTP</label>
                        <input type="number" className="form-control" id="email" placeholder="Enter OTP" {...register("otp", { required: true, pattern: {
                            value: /\b\d{6}\b/g,
                            message: "OTP should be of 6 digits"
                          } })} />
                          <div className="error">
                            {errors.otp?.type === 'required' && "OTP is required"}
                            {errors.otp?.type === 'pattern' && errors.otp.message}
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary waves-effect waves-light" type="submit">Verify</button>
                      </div>
                      <div className="mt-4 text-center">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                {/* <div>
                    <p>Don't have an account ? 
                      <Link to="/register" className="fw-medium text-primary">Signup now</Link></p>
                </div> */}
            </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Otp);