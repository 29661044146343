import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import Alert from '../../Services/Alert';
import Product from '../../Services/Product';
//import {amountFormat} from '../../Services/Custom';
import DataTable from 'react-data-table-component';
const Cusrrency = process?.env?.REACT_APP_CURRENCY_SYMBOL || "$";

export const Productslist = (props) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [isReset, setIsReset] = useState(0);

	function onDelete(id) {
        Alert.warning().then((result) => {
            if (result?.isConfirmed) {
                Product.delete(id).then(resp => {
                    if (resp.status) {
						Alert.success(resp.message);
						userData();
					} else {
						Alert.error(resp.message)
					}
                });
            }
        });
    }
	const columns = useMemo(() => [
		{
			name: '#',
			cell: (row, index) => index + 1  //RDT provides index by default
		},
		{
			name: 'Thumbnail',
			selector: (row, index) => {
				if (row?.image){
					return <img src={`${process.env.REACT_APP_API_URL}uploads/details/${row.image}`} alt="image" style={{height:"80px", width:"80px"}}/>
				}
				return <div>-</div>
			},
		},
		{
			name: 'Name',
			selector: row => {
				if (!row.name){
					return <div>-</div>
				}
				return row.name
			},
		},

		{
			name: 'Description',
			selector: row => {
				return <div title={row.description}>{row.description.substr(0, 50)}</div>;
			}
		},
		{
			name: 'Price Per Block',
			selector: row => (row.price_each_block) ? parseInt(row.price_each_block) + Cusrrency : '0'+ Cusrrency
		},
		{
			name: 'No. Of Block',
			selector: row => {
				return `(${row?.no_of_rows || 0} * ${row?.no_of_cols || 0}) ${row.no_of_block}`
			}
		},
		{
			name: 'Status',
			selector: row => {
				if (row.is_active){
					return <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, '0')}>Active</button>
				} else {
					return <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, '1')}>In Active</button>
				}
			},
		},
		{
			name: 'Action',
			selector: (item, ind) => {
				return <>
					{/* <Link to={'/products/view/' + item._id} className="btn btn-sm btn-success"><i className="fa fa-eye"></i>
					</Link>&nbsp; */}
					<Link to={'/products/edit/' + item._id} className="btn btn-sm btn-primary"><i className="fa fa-pencil-alt"></i>
					</Link>&nbsp;
					<button className="btn btn-sm btn-danger" title="Delete" onClick={() => onDelete(item._id)}><i className="fa fa-trash"></i></button>
				</>
			},
		},
	]
	);

	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setStatus(e.target.value);
	};
	const handleFilter = () => {
		//userData(1);
		setIsReset(!isReset);
	};
	function handleResetFilter() {
		setSearch(""); setStatus("");
		//userData(1);
		setIsReset(0);
	}
	const userData = async page => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: search, status: status };
		const response = await Product.list(sendd);
		setData(response.data.data.docs);
		setTotalRows(response.data.total);
		setLoading(false);
	};
	const handlePageChange = page => {
		userData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: '' };
		const response = await Product.list(sendd)
		setData(response.data.data.docs);
		setPerPage(newPerPage);
		setLoading(false);
	};
	
	useEffect(() => {
		userData(1);
	}, [isReset]);

	const onUpdateSt = (id, stto,is_widthraw=0) => {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				Product.update_status({ id: id, status: stto }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	return (
		<>
			<section className="main-content userlist_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="header-text">
											<h4 className="card-title mb-4">Products</h4>
											<div className="edit-btn">
												<Link to="/products/add" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
													<i className='bx bx-add-to-queue'></i>
													Add
												</Link>
											</div>
										</div>
										
										<div className="row">
											<div className="col-lg-3 col-md-3">
												<input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} />
											</div>
											<div className="col-lg-3 col-md-3">
												<select value={status} className="form-control" onChange={(e) => handleChangeStatus(e)}>
													<option value="">Both</option>
													<option value="1">Active</option>
													<option value="0">In Active</option>
												</select>
											</div>
											<div className="col-lg-3 col-md-3">
												<button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
												<button type="button" className="btn btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() =>handleResetFilter()}>Reset</button>
											</div>
										</div>
										<div className="table-responsive">
											<DataTable
												columns={columns}
												data={data}
												progressPending={loading}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
											/>
										</div>
										{/* end table-responsive  */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default withRouter(Productslist);