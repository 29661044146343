import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import moment from "moment";
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import Pagination from '../Utils/Pagination';
import { useLoading } from "../Services/LoadingContext";
const Cusrrency = process?.env?.REACT_APP_CURRENCY_SYMBOL || "$";

export const SalesAll = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [allRequests, setAllRequests] = useState([]);
  const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [payment, setPayment] = useState('');
  const history = useHistory();
  const { setLoading } = useLoading();

  const getAllRequstsData = async(page = "", limit = "") => {
    setLoading(true);
    Api.get(`admin/sales-list?page=${page}&limit=${limit}&status=${payment}`).then(res=>res.data).then(res => {
      setLoading(false);
      console.log('res', res);
      if (res.status && res.total > 0) {
        setAllRequests(res.data);
        setTotalRecords(res.total);
        setIsLoading(false);
      } else {
        setAllRequests([]);
        setTotalRecords(0);
        setIsLoading(false);
        // Alert.error(res.message)
      }
    }).catch(error => {
      setLoading(false);
      setAllRequests([]);
      setIsLoading(false);
      // Alert.error(error.message)
    })
  }

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getAllRequstsData(pageNew, limit);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getAllRequstsData(0, parseInt(event.target.value, 10));
  };

  const viewRecord = async (data) => {
    console.log(data);
  }

  const updateStatus = async (data) => {
    Alert.updateRequestStatus()
    .then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const res = { email: data.email, _id: data._id, payment: 1 };
        updateStatusToDB(res);
      } else if (result.isDenied) {
        const res = { email: data.email, _id: data._id, payment: 2 };
        updateStatusToDB(res);
      }
    });
  }

  const updateStatusToDB = async (sdata) => {
    await Api.post('admin/update-sales-status', sdata).then(resp => {
      if (resp.status) {
        getAllRequstsData(page, limit);
        Alert.success(resp.message)
      } else {
        Alert.error(resp.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }
  
  const handleChangePayment = (e) => {
    setPayment(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllRequstsData(page, limit);
  }, [page, limit, payment]);

  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="card">
                  <div className="card-body"> */}
                    <h4 className="card-title mb-4">All Sales</h4>
                    
                    <div className="row">
                      <div className="col-sm-3">
                        <label>Filter By Status</label>
                        <select value={payment} className="form-control" onChange={(e) => handleChangePayment(e)}>
                          <option value={''}>All</option>
                          <option value={'0'}>Pending</option>
                          <option value={'1'}>Accepted</option>
                          <option value={'2'}>Rejected</option>
                        </select>
                      </div>
                      <div className="col-sm-12">
                        <div className="card card-table">
                          <div className="card-body">
                            <div className="table-responsive custom-pagination">
                              <table className="table table-stripped table-hover datatable all_request">
                                <thead className="thead-light">
                                  <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    {/* <th>Phone</th> */}
                                    <th>Product</th>
                                    <th>Purchased Block</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>Created Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isLoading && (
                                    <>
                                      {allRequests.map((request, index) => (
                                        <tr key={index}>
                                          <td>{index+1}</td>
                                          <td>{request?.name}</td>
                                          <td>{request?.email}</td>
                                          {/* <td>{request?.phone || '-'}</td> */}
                                          <td>{request?.product_id?.name || '-'}</td>
                                          <td>{request?.gridids || '-'} ({request?.no_of_block})</td>
                                          <td>{(request.price)?`${request.price}${Cusrrency}`:'-'} </td>
                                          <td>
                                            {/* {(request.payment == 1)?'Payment Success':'Pending'} */}
                                            { (request.status === 0)?<Button className={ `btn btn-primary` } onClick={() => updateStatus(request)}>Update Status</Button>:((request.status == 1)?<label className='badge bg-success' style={ { fontSize: '14px' } }>Accepted</label>:<label className='badge bg-danger' style={ { fontSize: '14px' } }>Rejected</label>) }
                                            
                                            {/* <Button className={`${(request.payment === 1) ? 'btn btn-success' : 'btn btn-danger'}`} onClick={() => updateStatus(request)}>{`${(request.payment === 1) ? 'Success' : 'Pending'}`}</Button> */}
                                          </td>
                                          <td>{moment(request.createdAt).format('DD MMM YYYY, hh:mm a')}</td>
                                          {/* <td>
                                            <a className='delete_action' style={{marginRight: '5px'}} onClick={() => viewRecord(request)}><i className='fa fa-eye'></i></a>
                                            <a className='delete_action' onClick={() => deleteRecord(request.email)}><i className='fa fa-trash'></i></a>
                                          </td> */}
                                        </tr>
                                      ))}
                                      {allRequests.length === 0 && (
                                        <tr>
                                          <td colSpan={10}>No Records Found.</td>
                                        </tr>
                                      )}
                                    </>
                                  )}
                                  <Pagination
                                    totalItem={totalRecords}
                                    itemsPerPage={limit}
                                    page={page}
                                    handleChangePage={handlePageChange}
                                    handleChangeRowsPerPage={handleLimitChange}
                                    colSpan={8}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  {/* </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(SalesAll);