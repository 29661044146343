import React from "react";
import { Link } from 'react-router-dom';


const Header = ({ booknowredirect, booknowbtn }) => {
  return (
    <header className="header sticky-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container container-custom-width">
          <Link to="/" className="navbar-brand">Logo</Link>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
            </div> */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">Contact</Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">Terms</Link>
            </li>
            <li className="nav-item">
              <button type="button" className={`btn btn-primary buy-btn`} disabled={booknowbtn === false} id="booknow" onClick={() => booknowredirect()}>
                Buy
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
