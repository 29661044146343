import axios from "axios";
require('dotenv').config();
const API_URL = process.env.REACT_APP_API_URL;

let headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
    //'content-type': 'multipart/form-data',
};

class Api {
    constructor(props){
        axios.interceptors.request.use((req) => {
            req.headers = headers;
            if(localStorage.getItem('authToken')){
                var token = localStorage.getItem('authToken');
                req.headers.Authorization = 'Bearer '+token;
            }
            return req;
        });
    }
    
    async post(url, obj) {
        const resp = await axios.post(API_URL + url, obj, { headers });
        if (resp.data.status === 0 && resp.data.message === 'Invalid Token') {
            localStorage.clear();
            window.location.href = "/login";
        }
        return resp;
    }

    async get(url) {
        const resp = await axios.get(API_URL + url, { headers });
        if (resp.data.status === 0 && resp.data.message === 'Invalid Token') {
            localStorage.clear();
            window.location.href = "/login";
        }
        return resp;
    }
}
export default new Api();