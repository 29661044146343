import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Api from "../Services/Api";

export const Dashboard = (props) => {

  const [dataData, setDashData] = useState();
  const getCount = async() => {
    Api.get(`admin/get-dashboard-count`).then(res=>res.data).then(res => {
      setDashData(res.data);
    }).catch(error => {
      console.log(error);
      // Alert.error(error.message)
    })
  };
  useEffect(()=>{
    getCount();
  },[]);

  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="">
                  <div className="card-body text-center">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Dashboard</h4>
                    </div>
                    {/* Statistics Start */}
                    <div class="row">
                      <div class="col-md-4">
                        <div class="card mini-stats-wid">
                          <div class="card-body">
                            <div class="media">
                              <div class="media-body">
                                <p class="text-muted fw-bold">Total Product</p>
                                <h4 class="mb-0">
                                  {dataData?.products || 0}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="card mini-stats-wid">
                          <div class="card-body">
                            <div class="media">
                              <div class="media-body">
                                <p class="text-muted fw-bold">
                                  Total Sales
                                </p>
                                <h4 class="mb-0">
                                {dataData?.sales || 0}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card mini-stats-wid">
                          <div class="card-body">
                            <div class="media">
                              <div class="media-body">
                                <p class="text-muted fw-bold">Total Contact Us</p>
                                <h4 class="mb-0">
                                  {dataData?.contact || 0}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Statistics End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(Dashboard);
