import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import logos from '../images/logo.svg';
import site_logo from '../images/logo.png';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import logoDark from '../images/logo-dark.png';
import { routes } from '../Services/constants';

export const Userlist = (props) => {
    var history = useHistory();
    var [sessadminname, setSessadminname] = useState('');

    const doLogout = () => {
        Api.post('admin/logout').then((res) => res.data).then(res => {
            if (res.status) {
                localStorage.clear();
                history.push("/login");
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            var sessData = localStorage.getItem('admin');
            sessData = JSON.parse(sessData);
            var sFullname = sessData.firstname + ' ' + sessData.lastname;
            setSessadminname(sFullname);
        }
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <a href="index.html" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logos} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </a>

                                <Link to="/dashboard" className="logo logo-light">
                                    {/* <h1 className="mt-4">LOGO</h1> */}
                                    <span className="logo-sm">
                              <img src={site_logo} alt="" width="30px" />
                          </span>
                          <span className="logo-lg">
                              <img src={site_logo} alt="" width="70px" />
                          </span>
                                </Link>
                                {/* <a href="#;" className="logo logo-light">
                        <h1 className="mt-4">LOGO</h1>
                          <span className="logo-sm">
                              <img src="./assets/images/logo-light.svg" alt="" height="22" />
                          </span>
                          <span className="logo-lg">
                              <img src="./assets/images/logo-light.png" alt="" height="19" />
                          </span>
                      </a> */}
                            </div>

                            {/* <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button> */}
                        </div>

                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ms-2">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                    aria-labelledby="page-header-search-dropdown">

                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="dropdown d-inline-block">
                                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="d-none d-xl-inline-block ms-1" key="t-henry">{ sessadminname.replace('admin','').replace('Admin','') }</span>
                                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">

                                    <Link to="/change-password" className="dropdown-item">
                                        <i className="bx bx-lock font-size-16 align-middle me-1"></i> <span key="t-profile">Change Password</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item text-danger" type="button" onClick={() => doLogout()}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Left Sidebar Start */}
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        {/* Sidemenu  */}
                        <div id="sidebar-menu">
                            {/* Left Menu Start */}
                            <ul className="metismenu list-unstyled" id="side-menu">
                                <li className="menu-title" key="t-menu">Menu</li>
                                <li>
                                    <Link to={routes.dashboard} className="waves-effect">
                                        <i className='bx bx-desktop' ></i>
                                        <span key="t-calendar">Dashboard</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routes.products} className="waves-effect">
                                        <i className='mdi mdi-inbox' ></i>
                                        <span key="t-calendar">Products</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routes.sales} className="waves-effect">
                                        <i className='mdi mdi-inbox' ></i>
                                        <span key="t-calendar">Sales</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* Sidebar  */}
                    </div>
                </div>
                {/* Left Sidebar End */}
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            {/* 2023 © Fund Raiser */}
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                {/* Design & Develop by Hovodigital */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default withRouter(Userlist);