import React, { useEffect, useState } from 'react'
// import {Modal, Button, Table, Form } from 'react-bootstrap';
// import logo from "../../src/assets/images/logo.png";
import PrivateRoute from '../Services/PrivateRoute';
import { withRouter, Route, Switch, useLocation } from "react-router-dom";
import * as Comp from "./Index";
import MenuBar from './MenuBar';
import Loader from './Loader';
import { useLoading } from "../Services/LoadingContext";

function Layout(props) {
  // const history = useHistory();
  const location = useLocation();
  const [accessToken, setaccessToken] = useState(localStorage.getItem('authToken')||'')
  const [setIsShowLoader] = useState(0)
  const {loading} = useLoading()
  
  useEffect(() => {
    if(localStorage.getItem('authToken'))
      setaccessToken(localStorage.getItem('authToken'))
    else
      setaccessToken(null)
  }, [props])
  const hideShowLoader = async (isshow = 0) => {
    setIsShowLoader(isshow);
  }

  return (
    <>
      <header>
        {/* after authentication need to implement this for menu */}
        { accessToken && accessToken!=="" && location.pathname !== '/' && location.pathname !== '/welcome' && location.pathname !== '/details' && location.pathname !== '/frontend' && 
          <MenuBar />
        }
        {/* (()=>{
          if((props.location.pathname!=='/login'&&props.location.pathname!=='/register'&&props.location.pathname!=='/forgot-password')){
            return (<MenuBar />)
          }
        })() */}
      </header>
      <main>
        { loading && <Loader loading={ true }/> }
        <Switch>
          {/* {sessionStorage.getItem('admin') ? <Route path="/" exact component={Comp.Home} /> : <Route path="/" exact component={Comp.Login} />} */}
          
          {/* <PrivateRoute path="/" exact component={Comp.Login} /> */}
          <Route path="/" exact component={Comp.Login} />
          <Route path="/welcome" exact component={Comp.HomePage} />
          <Route path="/login" exact component={Comp.Login} />
          <Route path="/change-password" exact component={Comp.ChangePassword} />
          <Route path="/otp" exact component={Comp.Otp} />
          {/* <Route path="/register" exact component={Comp.Register} /> */}
          <Route path="/frontend" exact component={Comp.Home} />
          <Route path="/home" exact component={Comp.Forgot} />
          <Route path="/details" exact component={Comp.Details} />
          <PrivateRoute exact path="/dashboard" component={Comp.Dashboard} pageLoader={hideShowLoader} />
          <PrivateRoute exact path="/products" component={Comp.Productslist} pageLoader={hideShowLoader} />
          <PrivateRoute exact path="/products/add" component={Comp.ProductAdd} pageLoader={hideShowLoader} />
          <PrivateRoute exact path="/products/edit/:id" component={Comp.ProductAdd} />
          <PrivateRoute path="/sales" component={Comp.SalesAll} pageLoader={hideShowLoader} />
          <PrivateRoute path="/preview" exact component={Comp.PreviewImage} />
          <PrivateRoute path="*" component={Comp.Login} />
        </Switch>
      </main>
    </>
  );
}

export default withRouter(Layout);