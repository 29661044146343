import React from "react";

const Footer = () => {
  return (
    <>
      {/* <footer className="footer">
        <div className="row">
          <div className="col-lg-12">
            <div className="container">
              <div className="text-center">
                <h6>Footer</h6>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default Footer;
