import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Api from '../Services/Api';

export const HomePage = (props) => {
  
  let [imageData, setImageData] = useState({ desktop_image: null, mobile_image: null });
  
  useEffect(() => {
    getImageData()
  }, []);
  const getImageData = async () => {
    Api.get('user/get-home').then(resp => {
      var respData = resp.data;
      if (respData.status == 1) {
        let tempData = {
            desktop_image: respData.data.base_url + respData.data.desktop_image,
            mobile_image: respData.data.base_url + respData.data.mobile_image
        }
        setImageData(tempData);
      } else {
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-8">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  
                  <div className="p-2">
                    <div className='d-none d-sm-block'>
                        <img src={imageData.desktop_image} className='img' />
                    </div>
                    <div className='d-sm-none'>
                        <img src={imageData.mobile_image} className='img' />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(HomePage);