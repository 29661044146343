import Swal from "sweetalert2";

class Alert {
  constructor(props) {
    this.Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

  }

  errorToast(message) {
    this.Toast.fire({
      icon: "error",
      title: message,
    });
  }

  success(message) {
    Swal.fire({
      title: "Success",
      icon: "success",
      text: message,
      confirmButtonColor: '#3085d6',
    });
  }

  error(message) {
    Swal.fire({
      title: "Oops...",
      icon: "error",
      text: message,
    });
  }

  warning(message) {
    return Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      text: message,
      buttons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    });
  }
  updateRequestStatus() {
    return Swal.fire({
      title: 'Are you sure you want to \n update payment status?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Accept',
      confirmButtonColor: "#34c38f",
      denyButtonText: `Reject`,
      denyButtonColor: `#f46a6a`,
      allowOutsideClick: false,
    })
  }
  
}
export default new Alert();